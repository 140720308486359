<template>
  <div
    class="vlive_footer"
    :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
    v-if="$route.name !== 'subscriberVerify' && $route.name !== 'seeAllPlans'"
  >
    <div class="footer">
      <div  class="logo_container">
        <div class="flex_container">
          <div  class="download_our_app">
            <p tabindex="1" class="download_text">{{ $t("Download now") }}</p>
            <ul tabindex="4" class="app_links_container">
              <li class="app-links" v-if="appConfig && appConfig.playstore">
                <a  :href="appConfig.playstore.android"  target="_blank">
                  <div>
                    <googleplaystoresvg></googleplaystoresvg>
                  </div>
                </a>
              </li>
              <li class="app-links" v-if="appConfig && appConfig.playstore">
                <a :href="appConfig.playstore.apple"  target="_blank">
                  <div>
                    <applestoreicon></applestoreicon>
                  </div>
                </a>
              </li>
            
      </ul>

      <p tabindex="1" class="download_text">{{ $t("Also Available on") }}</p>
      <ul tabindex="4" class="app_links_container">

          <li class="app-links" v-if="appConfig.playstore.androidtv">
                  <a :href="appConfig.playstore.androidtv"  target="_blank">
              <div> 

                <img src="@/assets/icons/platforms/an100.png" alt="android-tv" />
              </div>
            </a>
          </li>

          <li class="app-links" v-if="appConfig && appConfig.playstore">
              <a :href="appConfig.playstore.firetv"  target="_blank">
              <div style="margin-left: 3px;">
                <img src="@/assets/icons/platforms/fv100.png" alt="firetv" />

              </div>
            </a>
          </li>
          <li class="app-links" v-if="appConfig && appConfig.playstore && appConfig.playstore.lgsmarttv">
              <a :href="appConfig.playstore.lgsmarttv"  target="_blank">
              <div style="margin-left: 3px;">
                <img src="@/assets/icons/platforms/l100.png" alt="lgsmarttv" />
              </div>
            </a>
          </li>
          <li class="app-links" v-if="appConfig && appConfig.playstore && appConfig.playstore.samungsmarttv">
              <a :href="appConfig.playstore.samungsmarttv"  target="_blank">
              <div style="margin-left: 3px;">
                <img src="@/assets/icons/platforms/s100.png" alt="samungsmarttv" />
              </div>
            </a>
          </li>
          <li class="app-links" v-if="appConfig && appConfig.playstore && appConfig.playstore.appletv">
              <a :href="appConfig.playstore.appletv"  target="_blank">
              <div style="margin-left: 3px;">
                <img src="@/assets/icons/platforms/a.png" alt="appletv" />
              </div>
            </a>
          </li>


        </ul>
          </div>
          <div class="social_icons">
            <div class="icon-paper">{{ $t("Connect with us") }}</div>
            <ul  class="icon_list">
              <li tabindex="1" class="icon" v-if="appConfig.providerDetails.social.facebook" @mouseover="actSoicalLogin('fb')">
                <a 
                  :href="isActivefacebookLink ? appConfig.providerDetails.social.facebook:''"
                  rel="noopener"
                  aria-label="facebook link"
                  target="_blank"
                >
                  <img
                    src="@/assets/icons/facebookicon.svg"
                    alt="facebook_img"
                  />
                </a>
              </li>
              <li
                class="icon"
                tabindex="1"
                v-if="appConfig.providerDetails.social.instagram"
                @mouseover="actSoicalLogin('insta')"
                
              >
                <a
                  :href="isActiveInstagramLink ? appConfig.providerDetails.social.instagram : ''"
                  rel="noopener"
                  aria-label="instagram link"
                  target="_blank"
                >
                  <img
                    src="@/assets/icons/instagramicon.svg"
                    alt="instagram_img"
                    style="width: 20px; height: 20px"
                  />
                </a>
              </li>
              <li 
              tabindex="1"
              class="icon" v-if="appConfig.providerDetails.social.twitter"  @mouseover="actSoicalLogin('twitter')"
>
                <a 
                  :href="isActiveTwitterLink ? appConfig.providerDetails.social.twitter : ''"
                  rel="noopener"
                  aria-label="twitter link"
                  target="_blank"
                  
                >
                  <img src="@/assets/icons/twittericon.svg" alt="twitter_img" />
                </a>
              </li>

              <li 
              tabindex="1"
              class="icon" v-if="appConfig.providerDetails.social.youtube" @mouseover="actSoicalLogin('tube')">
                <a 
                  :href="isActiveYoutubeLink ? appConfig.providerDetails.social.youtube : ''"
                  rel="noopener"
                  aria-label="pinterest link"
                  target="_blank"
                >
                  <img src="@/assets/icons/Youtubeicon.svg" alt="youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footerMenu">        
        <ul>
          <li  class="noSelect">
            <router-link :to="showAbout()" tabindex="1" class="noSelect">{{
              $t("About Us")
            }}</router-link>
          </li>
          <li >
            <router-link tabindex="1" class="noSelect"  :to="showTermsOfUse()">
               {{ $t("terms of use") }}</router-link>
            <!-- <router-link  class="noSelect" :to="isActiveTerms ?'/TermsOfUse' : ''">
              {{ $t("terms of use") }}
            </router-link> -->
          </li>

          <li>
            <router-link  tabindex="1" class="noSelect"  :to="showPrivacyPolicy()">
               {{ $t("privacy policy") }}</router-link>
              <!-- <router-link tabindex="1"  class="noSelect" :to="isActivePrivacy ? '/PrivacyPolicy'  : ''">
              {{ $t("privacy policy") }}
            </router-link> -->


          </li>

          <li>
            <router-link tabindex="1" class="noSelect globalDiv" :to="showFaq()">
           {{ $t("Help Center") }}
            </router-link>
          </li>

          <!-- <li>
            <div class="ot-sdk-show-settings"></div>
            <a tabindex="1" class="noSelect" href="mailto:support@ultrajhakaas.com"
              >support@ultrajhakaas.com</a
            >
          </li> -->
        </ul>
      </div>

      <div class="copyRightTxt">
        <p tabindex="1">
          {{ cpyRhTxt }}
        </p>
      </div>
    </div>
    <transition name="popup-animate">
      <div>
        <About
          v-if="showAboutPopup"
          :closePopup="() => toggleAboutPopup(false)"
        ></About>
        <Faq
          v-if="showFaqPopup"
          :closePopup="() => toggleFaqPopup(false)"
        ></Faq>
        <Contact
          v-if="showContactPopup"
          :closePopup="() => hideContactPopup(false)"
        ></Contact>
        <createTicket
          v-if="enableTicket"
          :closePopup="() => toggleCreateTicket(false)"
        ></createTicket>
        <AppReferal
          v-if="showAppShare"
          :closePopup="() => toggleAppReferal(false)"
        ></AppReferal>
      </div>
    </transition>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapMutations } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
import Utility from "@/mixins/Utility.js";

export default {
  data() {
    return {
      date: new Date(),
      brand: null,
      showAboutPopup: false,
      showFaqPopup: false,
      showContactPopup: false,
      enableTicket: false,
      showAppShare: false,
      providerUniqueId: _providerId,
      localDisplayLang: null,
      cpyRhTxt:"",
      isActivePrivacy: false,
      isActiveTerms:false,
      isActivefacebookLink:false,
      isActiveInstagramLink:false,
      isActiveTwitterLink:false,
      isActiveYoutubeLink:false,
      isActiveAboutusLink:false,
      isActiveShowAbout:false,
      isActiveShowFaq:false
    };
  },
  computed: {
    ...mapGetters(["appConfig", "getRtl"]),
  },
  watch: {
    showDeepHelp(val) {
      if (val) {
        this.showFaqPopup();
      }
    },
  },
  created() {
    
    this.brand =
      _projectName === "VLIVE"
        ? "vLive"
        : _projectName === "NET5"
        ? "Prime Originals"
        : _projectName === "NAMMAFLIX"
        ? "Anviton"
        : _projectName === "Noor Play"
        ? "Noorplay"
        : "";

    this.branchDeeplinks();

    eventBus.$on("show-contact-support", this.showContact);
    eventBus.$on("create-ticket-popup", this.showCreateTicket);
    eventBus.$on("show-share-app-popup", this.showAppReferral);

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    if (localStorage.getItem("setDisplayLanguageCode")) {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    }
    this.cpyRhTxt = this.$t(window.PROVIDER_SETUP_CONFIG.copyRight_Txt)
    this.isActivePrivacy  = window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled;
    this.isActiveTerms = window.PROVIDER_SETUP_CONFIG.isTermsEnabled;
    this.isActivefacebookLink = window.PROVIDER_SETUP_CONFIG.isFacebookEnabled;
    this.isActiveInstagramLink = window.PROVIDER_SETUP_CONFIG.isInstagramEnabled;
    this.isActiveTwitterLink = window.PROVIDER_SETUP_CONFIG.isTwitterEnabled;
    this.isActiveYoutubeLink =window.PROVIDER_SETUP_CONFIG.isYoutubeEnabled;
    this.isActiveAboutusLink =window.PROVIDER_SETUP_CONFIG.isAboutusEnabled;
    this.isActiveShowAbout = window.PROVIDER_SETUP_CONFIG.isShowAboutEnabled;
    this.isActiveShowFaq = window.PROVIDER_SETUP_CONFIG.isShowFaqEnabled;
  },
  methods: {
    ...mapMutations(["setRtl"]),
    actSoicalLogin (social) {
      let colorCode = '';
      if (social == 'fb') {
        colorCode = '#1877F2'
      } else if (social == 'insta') {
        colorCode = '#D748C0'
      } else if (social == 'twitter') {
        colorCode = '#1E90FF'
      } else if (social == 'tube') {
        colorCode = '#ED3833'
      } 
      document.documentElement.style.setProperty('--social-login-hover-color', colorCode);
    },
    showAbout() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if(!this.isActiveShowAbout){
        return;
      }
      return ({name: 'about-switch', params: {lang: currentLanguage}});
      // this.$router.push({name: 'about-switch', params: {lang: currentLanguage}});
    },
    showFaq() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (!this.isActiveShowFaq) {
        return;
      }
      return({name: 'faq-switch', params: {lang: currentLanguage}});
    },
    showTermsOfUse() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if(!this.isActiveTerms){
        return;
      }


      return({name: 'termsofuse', params: {lang: currentLanguage}});

    },
    showPrivacyPolicy() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if(!this.isActivePrivacy){
        return;
      }

      return({name: 'privacypolicy', params: {lang: currentLanguage}});

    },
    showContact() {
      this.showFaqPopup = false;
      this.showContactPopup = true;
      document.getElementById("body").style.overflowY = "hidden";
    },
    hideContactPopup(state) {
      this.showContactPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAboutPopup(state) {
      this.showAboutPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleFaqPopup(state) {
      this.showFaqPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    showCreateTicket(state) {
      this.showFaqPopup = false;
      this.enableTicket = state;
    },
    showAppReferral(state) {
      this.showAppShare = state;
      this.showAboutPopup = false;
    },
    toggleCreateTicket(state) {
      this.enableTicket = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAppReferal(state) {
      this.showAppShare = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    branchDeeplinks() {
      if (this.showDeepHelp) {
        this.showFaq();
      } else if (this.showDeepAbout) {
        this.showAbout();
      }
    },
  },
  components: {
    About: () =>
      import(/* webpackChunkName: "about" */ "@/components/Popups/AboutUs.vue"),
    Faq: () =>
      import(
        /* webpackChunkName: "Faq" */ "@/components/Popups/Help/FAQ/Faq.vue"
      ),
    Contact: () =>
      import(
        /* webpackChunkName: "contact" */ "@/components/Popups/Help/ContactSupport/ContactSupport.vue"
      ),
    createTicket: () =>
      import(
        /* webpackChunkName: "createTicket" */ "@/components/Popups/ProfilePopups/createTicket.vue"
      ),
    AppReferal: () =>
      import(
        /* webpackChunkName: "appReferal" */ "@/components/Popups/AppReferal.vue"
      ),
      googleplaystoresvg: ()=>
      import(
       /* webpackChunkName: "appReferal" */ "../SvgImages/Googleplaystroresvg.vue"
      ),
      applestoreicon:()=>
      import(
        /* webpackChunkName: "appReferal" */ "../SvgImages/AppStoreSvg.vue"
      ),
      firetvicon:()=>
      import(
         /* webpackChunkName: "appReferal" */ "../SvgImages/Firetvsvg.vue"
      ),
      andriodtviconsvg:()=>
      import(
         /* webpackChunkName: "appReferal" */ "../SvgImages/AndriodTvicon.vue"
      ),
  },
  mixins: [Utility],

};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./vlive_footer.scss";
 </style>